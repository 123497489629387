.viewselector {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.viewselector button {
    padding: .5rem .75rem;
    border: none;
    border-radius: .25rem;
}

.viewselector button.active {
    background-color: var(--bs-primary);
    color: #fff;
}

.viewselector button:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.viewselector button:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}


.mapcontainer {
    width: 100%;
    max-width: 1200px;
    height: 700px;
    padding: 0 3rem;
    margin-bottom: 3rem;
    margin: auto;
    position: relative;
}

.maploading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    word-break: break-all;
    padding: 3rem;
}

.maperror {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 0, 0, 0.5);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    word-break: break-all;
    padding: 3rem;
}

.mapkey {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    flex-direction: column;
    gap: .5rem;
}

.mapkey li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.mapkey li img {
    width: 25px;
    height: 41px;

}

.tablecontainer {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.filters {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.filters > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    flex-wrap: wrap;
}

.filters > div input {
    width: 6ch;
}

.table {
    margin: 2rem 0;
    max-width: 800px;
}