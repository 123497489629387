.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.aircraftContainer {
    width: 100%;
    background-color: #fff;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(59, 68, 161);
}

.aircraftContainer h1 {
    color: rgb(59, 68, 161);;
}

.aircraftContainer:nth-child(even) {
    background-color: rgb(59, 68, 161);
    color: #fff !important;
}

.aircraftContainer:nth-child(even) h1 {
    color: #fff;
}

.liverySelector {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.liverySelector button {
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: .25rem;
    padding: .125rem;
}

.liverySelector button.active {
    border: 2px solid #df2002;
}

.liverySelector button img {
    width: 100%;
    height: 100%;
}

.aircraftImage {
    width: 100%;
    max-width: 600px;
}