table {
    border: 5px solid;
    border-radius: .5rem;
    margin: 1rem 5rem;
    width: 100%;
}

thead {
    border-bottom: 2px solid;
}


td, th {
    padding: 1rem;
    border: 2px solid;
    text-align: center;
}

img {
    width: 100px;
}