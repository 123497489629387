.container {
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.regionSelector {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 600px;
    padding: .5rem;
    overflow-x: scroll;
    scrollbar-width: thin;
}

.regionSelector > button {
    padding: .5rem;
    border-radius: .25rem;
    border: 2px solid rgb(59, 68, 161);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: #000;
    transition: 1s;
}

.regionSelector > button.active {
    background-color: rgb(59, 68, 161);
    color: #fff;
}

.hubs {
    width: 100%;
    max-width: 1000px;
    background: rgb(59, 68, 161);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    justify-content: center;
    padding: 1rem;
    border-radius: .5rem;
}

.hubContainer {
    width: 100%;
    height: 100%;
    max-width: 300px;
    min-height: 300px;
    background-size: cover;
    background-position: right;
    display: flex;
    justify-content: end;
    flex-direction: column;
    padding: 2rem;
    position: relative;
    overflow: hidden;
}

.infoSlider {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(59, 68, 161, .75);
    z-index: 10;
    display: flex;
    justify-content: center;
    transition: .5s;
    padding: 1rem;
    color: #fff;
    transform: translateY(100%);
}

.hubContainer:hover .infoSlider {
    transform: translateY(0);
}