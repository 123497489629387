.fixed-top .container {
    transition: 0.5s;
}

.topbar {
    padding: 2px 10px 2px 20px;
    background: var(--bs-primary) !important;
}

.topbar a,
.topbar a i {
    transition: 0.5s;
}

.topbar a:hover,
.topbar a i:hover {
    color: var(--bs-secondary) !important;
}


@media (max-width: 768px) {
    .topbar {
        display: none;
    }
}